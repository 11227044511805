// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 4000px;

	&.back-top {
		width: 50px;
		height: 50px;
	}

	&.back-top {
		background-position: 0 0;
	}

	&.icon-all,
	&.icon-application-1,
	&.icon-application-2,
	&.icon-application-3,
	&.icon-application-4,
	&.icon-application-5,
	&.icon-application-6,
	&.icon-news-1,
	&.icon-news-2,
	&.icon-news-3,
	&.icon-product-1,
	&.icon-product-2,
	&.icon-product-3,
	&.icon-product-4,
	&.icon-product-5,
	&.icon-product-6,
	&.icon-product-7,
	&.icon-product-8,
	&.icon-product-9 {
		width: px(70);
		height: px(70);
	}

	&.icon-application-1 {
		background-position: -50px 0;
	}

	&.icon-application-2 {
		background-position: -120px 0;
	}

	&.icon-application-3 {
		background-position: -190px 0;
	}

	&.icon-application-4 {
		background-position: -260px 0;
	}

	&.icon-application-5 {
		background-position: -330px 0;
	}

	&.icon-application-6 {
		background-position: -400px 0;
	}


	&.icon-product-1 {
		background-position: -50px -70px;
	}

	&.icon-product-2 {
		background-position: -120px -70px;
	}

	&.icon-product-3 {
		background-position: -190px -70px;
	}

	&.icon-product-4 {
		background-position: -260px -70px;
	}

	&.icon-product-5 {
		background-position: -330px -70px;
	}

	&.icon-product-6 {
		background-position: -400px -70px;
	}


	&.icon-product-7 {
		background-position: -469px -70px;
	}

	&.icon-product-8 {
		background-position: -539px -70px;
	}

	&.icon-product-9 {
		background-position: -610px -70px;
	}


	&.icon-news-1 {
		background-position: -50px -140px;
	}

	&.icon-news-2 {
		background-position: -120px -140px;
	}

	&.icon-news-3 {
		background-position: -190px -140px;
	}

	&.icon-all {
		background-position: -470px 0;
	}

	&.icon-application-large-1,
	&.icon-application-large-2,
	&.icon-application-large-3,
	&.icon-application-large-4,
	&.icon-application-large-5,
	&.icon-application-large-6,
	&.icon-application-large-7,
	&.icon-application-large-8,
	&.icon-application-large-9,
	&.icon-application-large-10,
	&.icon-application-large-11,
	&.icon-application-large-12,
	&.icon-application-large-13,
	&.icon-application-large-14,
	&.icon-application-large-15,
	&.icon-application-large-16 {
		width: px(140);
		height: px(140);
	}

	&.icon-application-large-1 {
		background-position: -50px -220px;
	}
	&.icon-application-large-2 {
		background-position: -190px -220px;
	}
	&.icon-application-large-3 {
		background-position: -330px -220px;
	}
	&.icon-application-large-4 {
		background-position: -470px -220px;
	}
	&.icon-application-large-5 {
		background-position: -610px -220px;
	}
	&.icon-application-large-6 {
		background-position: -750px -220px;
	}
	&.icon-application-large-7 {
		background-position: -890px -220px;
	}
	&.icon-application-large-8 {
		background-position: -1030px -220px;
	}
	&.icon-application-large-9 {
		background-position: -1170px -220px;
	}
	&.icon-application-large-10 {
		background-position: -1310px -220px;
	}
	&.icon-application-large-11 {
		background-position: -1450px -220px;
	}
	&.icon-application-large-12 {
		background-position: -1590px -220px;
	}
	&.icon-application-large-13 {
		background-position: -1730px -220px;
	}
	&.icon-application-large-14 {
		background-position: -1870px -220px;
	}
	&.icon-application-large-15 {
		background-position: -2010px -220px;
	}
	&.icon-application-large-16 {
		background-position: -2150px -220px;
	}
}
