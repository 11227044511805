// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$gy-a: #f8f8f8;
$gy-b: #e5e6e8;
$gy-c: #DBD8D3;
$gy-d: #999999;
$gy-e: #40403E;
$gy-f: #2A2A29;
$bl-a: #908aab;
$bl-b: #736e9b;
$bl-c: #150765;
$br-a: #e2c283;
$br-b: #937240;
$ye-a : #977c4a;
// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}
.c-ye-a {
  color: $ye-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-gy-c {
  color: $gy-c;
}

.c-gy-d {
  color: $gy-d;
}
.c-gy-e {
  color: $gy-e;
}
.c-gy-f {
  color: $gy-f;
}

.c-bl-a {
  color: $bl-a;
}

.c-bl-b {
  color: $bl-b;
}

.c-bl-c {
  color: $bl-c;
}

.c-br-a {
  color: $br-a;
}

.c-br-b {
  color: $br-b;
}
// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}

.bg-gy-c {
  background-color: $gy-c;
}

.bg-gy-d {
  background-color: $gy-d;
}
.bg-gy-e {
  background-color: $gy-e;
}
.bg-gy-f {
  background-color: $gy-f;
}

.bg-bl-a {
  background-color: $bl-a;
}

.bg-bl-b {
  background-color: $bl-b;
}

.bg-bl-c {
  background-color: $bl-c;
}

.bg-br-a {
  background-color: $br-a;
}

.bg-br-b {
  background-color: $br-b;
}
