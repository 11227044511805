// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	border: none;
	display: inline-block;
	padding: px(11) px(18);
	line-height: 1.2em;
	font-size: px(14);
	min-width: px(180);
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	color: $gy-e;
	border-radius: px(30);
	transition: all 0.2s ease-in-out;
	&.except-small {
		font-size: px(12);
		text-transform: inherit;
		min-width: px(150);
	}
	&.white {
		background-color: $wh-a;
		&:focus,
		&:hover {
			color: $bl-c;
		}
	}
	&.yellow {
		background: #957542;
		background: -moz-linear-gradient(top,  #e2c181 1%, #957542 100%);
		background: -webkit-linear-gradient(top,  #e2c181 1%,#957542 100%);
		background: linear-gradient(to bottom,  #e2c181 1%,#957542 100%);
		color: $wh-a;
		&:focus,
		&:hover {
			background: #957542;
			background: -moz-linear-gradient(top,  shade(#e2c181,10%) 1%, shade(#957542,10%) 100%);
			background: -webkit-linear-gradient(top,  shade(#e2c181,10%) 1%,shade(#957542,10%) 100%);
			background: linear-gradient(to bottom,  shade(#e2c181,10%) 1%,shade(#957542,10%) 100%);
		}
	}
	&.blue {
		background: rgb(116,112,156);
		background: -moz-linear-gradient(top,  rgba(116,112,156,1) 1%, rgba(35,26,97,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(116,112,156,1) 1%,rgba(35,26,97,1) 100%);
		background: linear-gradient(to bottom,  rgba(116,112,156,1) 1%,rgba(35,26,97,1) 100%);
		color: $wh-a;
		&:focus,
		&:hover {
			background: rgb(116,112,156);
			background: -moz-linear-gradient(top,  shade(rgba(116,112,156,1),10%) 1%, shade(rgba(35,26,97,1),10%) 100%);
			background: -webkit-linear-gradient(top,  shade(rgba(116,112,156,1),10%) 1%,shade(rgba(35,26,97,1),10%) 100%);
			background: linear-gradient(to bottom,  shade(rgba(116,112,156,1),10%) 1%,shade(rgba(35,26,97,1),10%) 100%);
		}
	}
	&.green {
		background: #48ad51;
		background: -moz-linear-gradient(top,  #48ad51 1%,  #378c40 100%);
		background: -webkit-linear-gradient(top,  #48ad51 1%, #378c40 100%);
		background: linear-gradient(to bottom,  #48ad51 1%, #378c40 100%);
		color: $wh-a;
		&:focus,
		&:hover {
			background: #48ad51;
			background: -moz-linear-gradient(top,  shade(#48ad51 ,10%) 1%, shade(#378c40, 10%) 100%);
			background: -webkit-linear-gradient(top,  shade(#48ad51 ,10%) 1%,shade(#378c40, 10%) 100%);
			background: linear-gradient(to bottom,  shade(#48ad51 ,10%) 1%,shade(#378c40, 10%) 100%);
		}
	}
	@include media-breakpoint-down(xs) {
		font-size: px(13);
		min-width: px(180);
 	}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 26px;
	height: 16px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $wh-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 6px;
	}

	span:nth-child(4) {
		top: 12px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}

body.header-small {
	&.menu-open .btn-mobile-hamburger {
		span:nth-child(1) {
			top: 0;
			width: 100%;
			left: auto;
		}

		span:nth-child(2) {
			top: 6px;
			transform: rotate(0);
		}

		span:nth-child(3) {
			top: 6px;
			transform: rotate(0);
		}

		span:nth-child(4) {
			top: 12px;
			width: 100%;
			left: auto;
		}
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null null 30px 30px);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		left: 15px;
		bottom: 15px;
	}
	@include media-breakpoint-down(xs) {
		display: none !important;
	}
}
