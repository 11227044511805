.wysiwyg-wp {
  p.headline {
    font-weight: 600;
  }

  .title {
    @extend .h-like-c;
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 0;
  }

  ol,
  ul {
    li {
      padding-left: px(25);
      position: relative;
      font-size: px(18);
      text-align: left;

      &:not(:last-child) {
        margin-bottom: px(7);
      }
    }
  }

  ul {
    li:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: px(9);
      border-radius: 100%;
      width: px(8);
      height: px(8);
      background-color: transparent;
      border: 2px solid $gy-e;
    }
  }

  ol {
    list-style: none;
    counter-reset: ol-counter;

    li:before {
      content: counter(ol-counter)".";
      counter-increment: ol-counter;
      display: block;
      position: absolute;
      left: 0;
      top: px(0);
      font-weight: 600;
    }
  }

  .btn-wp {
    a {
      @extend .btn-a;
      @extend .blue;
    }
  }

  .btn-wp,
  h2,
  ol,
  p,
  ul {
    margin-top: px(30);
  }

  img {
    @extend .img-fluid;
  }
  @include media-breakpoint-down(sm) {
    img {
      max-width: px(300);
    }
    .btn-wp,
    h2,
    ol,
    p,
    ul {
      margin-top: px(15);
    }

    ol,
    ul {
      li {
        font-size: px(16);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .btn-wp,
    h2,
    ol,
    p,
    ul {
      margin-top: px(10);
    }

    ol,
    ul {
      li {
        font-size: px(15);
      }
    }
  }
}
