// Font family
$font-stack: 'Open Sans', sans-serif; // Html

html {
  color: $gy-f;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity 0.1s ease-in-out;
  color: $gy-f;
}
// Title :: soft reset
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: px(60);
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 1.2em;
}

.h-like-b {
  font-size: px(40);
  font-weight: 600;
  line-height: 1.2em;
}

.h-like-c {
  font-size: px(32);
  font-weight: 600;
  line-height: 1.2em;
  strong {
    font-weight: 700;
  }
}


.h-like-d {
  font-size: px(26);
  font-weight: 600;
  line-height: 1.4em;
}

.h-like-e {
  font-size: px(18);
  font-weight: 700;
  line-height: 1.4em;

  &.bolder {
    font-weight: 800;
  }
}

@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {
    font-size: px(56);
  }

  .h-like-b {
    font-size: px(34);
  }

  .h-like-c {
    font-size: px(30);
  }
  .h-like-d {
    font-size: px(22);
  }
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: px(46);
  }

  .h-like-b {
    font-size: px(32);
  }

  .h-like-c {
    font-size: px(28);
  }

  .h-like-d {
    font-size: px(22);
  }

  .h-like-e {
    font-size: px(16);
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    font-size: px(36);
    line-height: 1.1em;
  }

  .h-like-b {
    font-size: px(28);
  }

  .h-like-c {
    font-size: px(24);
  }
  .h-like-d {
    font-size: px(18);
  }

}
// Paragraph hierarchy :: Bigger to smaller

p {
  font-size: px(18);
  line-height: 1.75em;

  &.except-lh {
    line-height: 1.3em;
  }
}

.p-like-a {
  font-size: px(14);
  line-height: 1.4em;
}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {
  p {
    font-size: px(16);
    line-height: 1.5em;
  }
}
@include media-breakpoint-down(xs) {
  p {
    font-size: px(15);
    line-height: 1.4em;
  }
}
