// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll
body.header-small #header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
}
body.header-small #nav-article-wp {
	position: fixed;
	top: px(47);
	left: 0;
	width: 100%;
	z-index: 8;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
}
@include media-breakpoint-down(sm) {
	body.header-small #nav-article-wp {
		top: px(45);
	}
}@include media-breakpoint-down(xs) {
	body.header-small #nav-article-wp {
		top: px(0);
		width: calc(100%);
		left: px(0);
	}
}
@include media-breakpoint-down(xs) {
	body {
		padding-top: 0 !important;
	}
	body.header-small #header {
		position: relative;
		top: auto;
		left: auto;
		width: 100%;
	}
	body.header-small #header {
		#nav-mobile-trigger {
			position: fixed;
			width: px(50);
			height: px(50);
			top: auto;
			bottom: px(30);
			left: px(30);
			border-radius: 100%;
		}
	}
	#nav-top {
		margin-top: 0 !important;
	}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	margin-top: 0;
	background-color: $wh-a;
	transition: boxShadow 0.25s ease-in-out;

	@include media-breakpoint-down(xs) {
		margin-top: 0 !important;
	}

	&:after,
	&:before {
		content: "";
		width: 15px;
		height: 100%;
		position: absolute;
		top: 0;
		background-color: $wh-a;
		z-index: 2;
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}

	#nav-top {
		position: relative;
		padding: px(20) 0 px(10);

		&:after {
			content: "";
			width: 300%;
			height: px(1);
			background-color: $gy-b;
			position: absolute;
			left: -150%;
			bottom: 0;
		}

		.form-search-wp {
			height: 100%;
			padding-top: px(10);

			.btn-submit {
				display: none;
			}

			.input {
				width: 100%;
				display: block;
				background-color: transparent;
				border: none;
				ouline: none !important;
				font-size: px(18);
				color: $gy-e;
				height: calc(100% - 30px);
				line-height: 1em;
				padding: px(10);

				&::placeholder {
					opacity: 0;
				}

				&:focus {
					outline: none !important;
				}
			}
		}

		.list {
			text-align: right;

			.item {
				display: inline-block;
				vertical-align: top;
				margin: 0 px(5);

				&.addthis {
					width: px(17);
					padding-top: px(6);

					> div {
						margin-left: px(-14);
					}
				}

			}
		}

		.btn-wp {
			margin-top: px(5);
			text-align: center;
			&:hover{
				color: shade($gy-d, 20%);
				.txt {
					color: shade($gy-d, 20%);
				}
			}

			> * {
				display: block;
			}

			.txt {
				color: $gy-e;
				font-weight: 700;
				font-size: px(12);
				display: block;
				margin-top: px(2);
				transition: all 0.1s ease-in-out;
			}
		}

		.social-link {
			display: block;
			padding-top: px(8);
			font-size: px(18);
			color: $gy-e;
			transition: all 0.1s ease-in-out;
			&:hover,
			&:focus {
				color: $gy-d;
			}
		}
		@include media-breakpoint-down(md) {
			.list {
				.item {
					margin: 0 px(3);

					&.addthis {
						> div {
							margin-left: px(-12);
							margin-top: px(0);
						}
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			padding: px(10) 0 px(5);

			.form-search-wp {
				padding-top: 0;

				.input {
					height: calc(100% - 10px);
					font-size: px(13);
				}
			}

			.list {
				.item {
					margin: 0 px(4);

					&.addthis {
						> div {
							margin-left: px(-12);
							margin-top: px(-2);
						}
					}
				}
			}

			.btn-wp {
				padding-top: px(5);

				> .txt {
					display: none;
				}

				.icon {
					font-size: px(18);
					font-weight: bold;
				}
			}

			.social-link {
				padding-top: px(8);
				font-size: px(14);
			}
		}
		@include media-breakpoint-down(xs) {
			padding-bottom: px(10);

			.logo-wp {
				width: px(100);
			}

			.list {
				position: absolute;
				top: px(-45);
				right: px(65);
				.item {
					margin: 0 px(2);
					&.addthis {
						> div {
							margin-left: px(-10);
						}
					}
				}
			}

			.btn-wp {
				display: none;
			}

			.form-search-wp {
				position: relative;
				background-color: $gy-a;

				&:before {
					content: "";
					position: absolute;
					width: 100%;
					left: 0;
					height: px(1);
					top: px(-10);
					background-color: $gy-b;
				}

				.btn-submit {
					display: block;
					position: absolute;
					top: px(8);
					right: px(28);

					.icon {
						font-size: px(22);
					}
				}

				.input {
					height: 100% !important;
					display: block !important;
					padding-right: px(60) !important;

					&::placeholder {
						opacity: 1;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		#nav-mobile-trigger {
			background-color: $bl-c;
			padding: px(0);
			position: absolute;
			top: px(8);
			width: px(40);
			height: px(40);
			right: px(15);
			z-index: 10;
			border-radius: 100%;
			.btn-mobile-hamburger {
				width: px(20);
			}
		}
	}
}
// Navigation :: Main

#nav-main {
	display: flex;
	width: 100%;
	padding: px(18) 0;

	.item {
		flex-grow: 1;

		&:not(:last-child) {
			border-right: 1px solid $gy-e;
		}
	}

	.link {
		display: block;
		line-height: 0.8em;
		color: $gy-e;
		padding: 0 px(15);
		font-size: px(14);
		font-weight: 600;
		text-align: center;

		&.contact {
			position: relative;
			transition: all 0.1s ease-in-out;
			&:hover,
			&:focus {
				transform: scale(0.9);
			}
			.fa {
				position: absolute;
				color: $bl-c;
				left: 50%;
				margin-left: px(-10);
				top: px(-6);
				font-size: px(24);
			}
		}
	}

	.link:focus,
	.link:hover {
		color: $bl-c;
	}

	.link.active {
		color: $bl-c;
		font-weight: 700;
	}

	.nav-sub {
		border-top: 1px solid $gy-b;
		width: 100%;
		display: flex;
		justify-content: center;
		text-align: center;

		&.except-news {
			.item-sub {
				flex-grow: 0;
				margin: px(0) px(25);
			}
		}

		.item-sub {
			flex-grow: 1;
			text-align: center;
		}

		.link-sub {
			text-align: center;
			transition: all 0.1s ease-in-out;

			> * {
				margin: 0 auto;
				display: block;
			}

			.txt {
				font-size: px(13);
				line-height: 1.25em;
				font-weight: 600;
				margin-top: px(8);
			}
			.sprite {
				transition: all 0.1s ease-in-out;
			}
		}

		.link-sub:focus,
		.link-sub:hover {
			color: $bl-c;
			.sprite {
				transform: scale(0.95);
			}
		}

		.link-sub.active {
			color: $bl-c;
			.txt {
				font-weight: 700;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.link.contact {
			.fa {
				font-size: px(16);
				margin-left: px(-6);
				top: px(-3);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(10) 0;

		.nav-sub {
			top: px(108);

			.link-sub {
				.sprite {
					transform: scale(0.8);
				}

				.txt {
					margin-top: px(0);
					font-size: px(11);
				}
			}
		}

		.item {
			border-color: $gy-c !important;
		}

		.link {
			font-size: px(12);
			line-height: 1.1em;
			display: flex;
			height: 100%;
			align-items: center;

			&.contact {
				.fa {
					top: px(5);
					font-size: px(14);
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		display: none;
		padding: 0;

		.item {
			display: block;
			border: none !important;
			border-bottom: 1px solid $gy-b !important;

			.link {
				position: relative;
				width: 100%;
				display: block;
				padding: px(12) px(5);
				text-align: left;
				font-size: px(13);
			}

			&[data-navsub] {
				.link {
					&:after {
						content: "+";
						position: absolute;
						top: 50%;
						right: px(5);
						margin-top: px(-8);
						font-size: px(22);
						font-weight: 300;
					}

					&.open:after {
						content: "-";
						font-size: px(36);
						font-weight: 300;
						margin-top: px(-10);
					}
				}
			}

			.nav-sub {
				top: auto;
				left: auto;
				display: none;
				padding: 0;
				width: 100%;
				background-color: $gy-a;
				box-shadow: 0 1px 3px -2px inset rgba(0, 0, 0, 0.2);

				.item-sub {
					text-align: left;
					display: block;
					border-bottom: 1px solid $gy-b;
					margin: 0 !important;

					&:last-child {
						border-bottom: none;
					}

					.link-sub {
						display: block;
						text-align: left;
						padding: px(11) px(20) px(10) px(35);
						position: relative;

						.txt {
							font-size: px(12);
							font-weight: 400;

							br {
								display: none;
							}
						}

						.sprite {
							transform: scale(0.275) translate(-64px, -64px);
							position: absolute;
							left: 0;
							top: 0;
						}
					}
				}
			}
		}
	}
}
// Navigation :: Sub

.nav-sub {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	height: auto;
	opacity: 1;
}

li[data-navsub] {}


@include media-breakpoint-up(lg) {
	li[data-navsub].focus > .nav-sub {
		padding: px(25) px(30) px(20);
		top: px(158);
	}
}

@include media-breakpoint-down(lg) {
	li[data-navsub].focus > .nav-sub {
		padding: px(25) px(30) px(20);
		top: px(158);
	}

}
@include media-breakpoint-down(md) {
	li[data-navsub].focus > .nav-sub {
		padding: px(25) px(30) px(20);
		top: px(145);
	}
}@include media-breakpoint-down(sm) {
	li[data-navsub].focus > .nav-sub {
		padding: px(10) px(30) px(15);
		top: px(145);
	}
}


@include media-breakpoint-up(sm) {
	li[data-navsub].focus > .nav-sub {
		visibility: visible;
		display: flex;
		height: auto;
		opacity: 1;
	}

	.nav-sub {
		display: none;
		position: absolute;
		left: px(0);
		z-index: 99;
		overflow: hidden;
		height: 0;
		width: 100%;
		padding: 0;
		opacity: 0;
		background-color: $gy-a;
		transition: all 0.1 ease-in-out;
		box-shadow: 0 3px 4px -1px rgba(0,0,0,0.1);
	}
}

// Header page
.header-page-wp {
	position: relative;
	padding: 0 px(15);
	margin-bottom: px(18);
	.header {
		padding: px(15);
		background-color: $gy-e;
		color: $wh-a;
		.title-left {
			font-weight: 300;
			font-size: px(22);
			margin: 0;
		}
		.title-right {
			font-size: px(28);
			margin: 0;
			font-weight: 600;
		}
	}
	@include media-breakpoint-down(sm) {
		.header {
			padding: px(12) px(15);
			.title-left {
				font-size: px(20);
			}
			.title-right {
				font-size: px(24);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		margin-bottom: px(10);
		.header {
			.title-left {
				font-size: px(16);
			}
			.title-right {
				font-size: px(20);
				margin-top: px(-3);
			}
		}
	}
	.breadcrumb {
		background: transparent;
		padding: px(15) 0 0;
		margin-bottom: 0;
		.breadcrumb-item + .breadcrumb-item::before {
			content:">";
			font-size: px(12);
		}
		.breadcrumb-item.active {
			color: $gy-f;
			font-weight: 600;
		}
	}
	@include media-breakpoint-down(xs) {
		.breadcrumb {
			padding-top: px(10);
		}
	}
}
