// Footer

#footer {
	background-color: $gy-e;
	color: $wh-a;
	padding: px(50) 0 px(100) 0;

	.title {
		font-weight: 700;
		text-transform: uppercase;
		font-size: px(13);
		margin-bottom: px(20);
	}

	.list {
		color: $gy-d;

		&.icon-off {
			.item {
				display: block;
				border-bottom: 1px solid tint($gy-e, 20%);

				&:first-child .link {
					padding-top: px(0);

					.fas {
						top: px(4);
					}
				}

				.link {
					padding: px(8) 0 px(8) px(15);
					font-size: px(13);
					display: block;
					position: relative;
					transition: all 0.1s ease-in-out;
					&:hover,
					&:focus {
						color: $wh-a;
					}

					.fas {
						position: absolute;
						top: px(12);
						left: 0;
					}
				}
			}
		}

		&.icon-on {
			.item {
				display: block;

				&:not(:last-child) {
					margin-bottom: px(15);
				}
				a.link:hover,
				a.link:focus {
					color: $wh-a;
				}

				.link {
					font-size: px(13);
					display: block;
					position: relative;
					padding-left: px(25);
					line-height: 1.2em;
					transition: all 0.2s ease-in-out;
					.icon {
						top: 0;
						left: 0;
						position: absolute;
					}
				}
			}
		}
	}

	.list-social {
		.item {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: px(10);

			&:not(:last-child) {
				margin-right: px(6);
			}

			.social-link {
				display: block;
				width: px(34);
				height: px(34);
				border-radius: 100%;
				border: 1px solid $wh-a;
				text-align: center;
				padding-top: px(6);
				transition: all 0.2s ease-in-out;
				&:hover,
				&:focus {
					opacity: 0.8;
				}
				&.addthis {
					padding-top: px(1);
					.addthis_inline_share_toolbox {
						margin-left: px(-1);
					}
				}
				.fab {
					font-size: px(17);
				}
				@include media-breakpoint-down(md) {
					width: px(26);
					height: px(26);
					padding-top: px(1);
					&.addthis {
						.addthis_inline_share_toolbox {
							margin: px(-4) 0 0 px(-5);
						}
					}
					.fab {
						font-size: px(14);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(30) 0;

		.title {
			margin-bottom: px(13);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0;

		.title {
			margin-bottom: px(13);
		}
	}
}

// Cookie Consent
#cookie-consent-wp {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: px(15) px(100) px(20) px(20);
	z-index: 10000;
	background-color: $gy-f;
	color: $wh-a;
	display: none;

	.txt {
		font-size: px(14);
		padding-bottom: 0;
		margin-bottom: 0;
		line-height: 1.3em;
		display: block;
	}

	.link {
		color: $wh-a;
		text-decoration: underline;
	}

	.close {
		position: absolute;
		top: 50%;
		right: px(30);
		margin-top: px(-12);
		font-size: px(30);
		float: none;
		opacity: 1;
		text-shadow: none;
		font-weight: 300;
		color: #FFF !important;
	}
	@include media-breakpoint-down(xs) {
		padding: px(15) px(45) px(15) px(15);

		.txt {
			font-size: px(12);
		}

		.close {
			right: px(10);
		}
	}
}
