// Body

html {
	overflow-x: hidden;
	width: 100%;
}

body {
	position: relative;
	max-width: 100%;
	overflow-x: hidden;

	&:after,
	&:before {
		content: "";
		position: absolute;
		top: 0;
		width: px(15);
		height: 100%;
		z-index: 1000;
		background-color: $wh-a;
	}

	&:after {
		display: none;
		left: auto;
	}

	&:before {
		display: none;
		right: auto;
	}
	@include media-breakpoint-down(xs) {
		&:after,
		&:before {
			display: none;
		}
	}
}

body,
html {
	width: 100%;
	background-color: #fff;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

img.mfp-img {
	max-width: 1140px;
	@include media-breakpoint-down(lg){
		max-width: 100%;
		width: calc(100% - 120px) !important;
		margin-left: 60px;
	}
}
@include media-breakpoint-down(lg){
	.mfp-container .mfp-iframe {
		width: calc(100% - 120px) !important;
		margin-left: 60px;
	}
	.mfp-title {
		text-align: center;
	}
	.mfp-counter {
		display: none;
	}
	.mfp-figure:after{
		display: none;

	}
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Section Stories

.section-pattern {
	padding: px(125) 0;
	background-image: url("../images/illustrations/pattern-bg.jpg");
	background-repeat: no-repeat;
	background-position: top center;
	@include media-breakpoint-down(lg) {
		padding: px(100) 0;
	}
	@include media-breakpoint-down(md) {
		padding: px(75) 0;
	}
	@include media-breakpoint-down(sm) {
		padding: px(50) 0;
	}
	@include media-breakpoint-down(xs) {
		background: none !important;
		padding: px(30) 0;
	}
}
// Article Push

.article-push {
	display: flex;
	flex-flow: column wrap;
	height: 100%;
	text-align: center;
	justify-content: space-between;

	.col-top {
		padding-bottom: px(20);
	}

	.img-fluid {
		max-width: px(250);
	}
	@include media-breakpoint-down(sm) {
		.img-fluid {
			max-width: 100%;
		}
	}
	@include media-breakpoint-down(xs) {
		.col-top {
			padding-bottom: px(0);
		}

		.img-fluid {
			max-width: 50%;
		}
	}
}
// Separator backrounds

.bg-separator {
	position: relative;
	background-attachment: scroll;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("../images/illustrations/separator-bg.jpg");
	width: 100%;
	padding: px(100) 0;
	color: $wh-a;
	text-align: center;
	@include media-breakpoint-down(md) {
		padding: px(75) 0;
	}
	@include media-breakpoint-down(sm) {
		padding: px(50) 0;
	}
}

.section-club {
	padding: px(40) px(15) px(60);
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("../images/illustrations/club-bg.jpg");
	color: $wh-a;
	@include media-breakpoint-down(sm) {
		.img-fluid {
			width: px(250);
		}
		text-align: center;
		background-image: url("../images/illustrations/club-bg-mobile.jpg");
	}
	@include media-breakpoint-down(xs) {
		padding: px(30) px(15) px(50);

		.img-fluid {
			width: px(200);
		}
	}
}
// Section Form

.section-form {
	position: relative;
	padding: px(100) 0;

	.container {
		padding-right: px(400);
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		right: px(0);
		margin-top: px(-200);
		background-image: url("../images/illustrations/form-icon.png");
		background-repeat: no-repeat;
		width: px(400);
		height: px(400);
	}
	@include media-breakpoint-down(lg){
		&:after {
			margin-top: px(-175);
			width: px(350);
			height: px(350);
			background-size: px(350);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(75) px(0);
		.container {
			padding-right: px(15);
		}

		&:after {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(50) px(0);
	}
	@include media-breakpoint-down(xs) {
		padding: px(30) px(0);
	}
}
// Team

.team-item {
	position: relative;
	background-color: $gy-b;
	color: $bl-c;
	height: 100%;

	.content {
		padding: px(16) px(20) px(20);
	}

	.list {
		margin-top: px(13);

		.item:not(:last-child) {
			margin-bottom: px(5);
		}

		.link {
			position: relative;
			display: block;
			padding-left: px(25);
			font-size: px(13.5);
			line-height: 1.2em;
			word-wrap: break-word;

			.fas {
				position: absolute;
				top: px(2);
				left: 0;
			}
		}
	}
}
// About
.about-ambassador,
.about-history {
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
	text-align: center;
	color: $wh-a;
	padding: px(150) 0;

	&.about-ambassador {
		background-image: url("../images/illustrations/about-ambassador-bg.jpg");
	}

	&.about-history {
		background-image: url("../images/illustrations/about-history-bg.jpg");
	}

	.list {
		.item:not(:last-child) {
			margin-bottom: px(15);
		}

		.date {
			font-size: px(26);
			font-weight: 700;
		}

		.txt {
			margin-top: px(5);
			font-size: px(18);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(100) 0;
	}
	@include media-breakpoint-down(sm) {
		padding: px(50) 0;

		.list {
			.date {
				font-size: px(22);
			}

			.txt {
				font-size: px(16);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		background-size: cover;
		background-position: center center;
		background-attachment: inherit;

		.list {
			.date {
				font-size: px(20);
			}

			.txt {
				font-size: px(15);
			}
		}
	}
}
// Applications

.application-item {
	position: relative;
	padding-left: px(165);

	.col-left {
		position: absolute;
		left: 0;
		top: 0;
	}

	.col-right {
		position: relative;
		min-height: px(180);
		padding-left: px(20);
		border-left: 1px solid $gy-e;
		color: $bl-c;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background-color: $gy-e;
			height: px(50);
			width: px(4);
			display: block;
		}

		.title {
			font-weight: 600;
			font-size: px(22);
		}

		.txt {
			margin-top: px(5);
			line-height: 1.5em;
			font-size: px(16);
		}

		.btn-wp {
			margin-top: px(20);
		}
	}
	@include media-breakpoint-down(sm) {
		padding-left: px(160);

		.col-left {
			.sprite {
				transform: scale(0.8) translate(-15px, -20px);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		padding-left: px(0);
		text-align: center;

		.col-left {
			position: relative;
			top: auto;
			left: auto;

			.sprite {
				transform: scale(1) translate(0, 0);
			}
		}

		.col-right {
			margin-top: px(15);
			min-height: 0;
			border: none;

			&:before {
				display: none;
			}

			.title {
				font-size: px(20);
			}

			.txt {
				font-size: px(15);
			}

			.btn-wp {
				margin-top: px(10);
			}
		}
	}
}
// Navigation filter list

.list-filter-wp {
	background-color: $gy-b;

	#filter-title {
		padding: px(30) px(15) 0;
	}

	.form-wrapper {
		padding: px(15) 0;
	}
	@include media-breakpoint-down(md) {
		.form-wrapper {
			.container {
				position: relative;
				padding-right: px(100);
			}

			.btn-action {
				position: absolute;
				top: 0;
				right: px(15);
				min-width: px(35);
				padding-left: px(10);
				padding-right: px(10);
			}

			.col-lg-3 {
				position: static;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		#filter-title {
			padding-top: px(20);
		}
	}
	@include media-breakpoint-down(xs) {
		.form-wrapper {
			.container {
				padding-right: px(15);
			}

			.btn-action {
				position: relative;
				top: auto;
				right: auto;
			}
		}

		#filter-title {
			padding-top: px(15);
		}
	}
}

body.fixed-nav {
	padding-top: px(220) !important;

	#filter-nav {
		position: fixed;
		top: px(47);
		width: 100%;
		background-color: $gy-b;
		z-index: 9;
		border-bottom: 1px solid $gy-c;
	}
	@include media-breakpoint-down(md) {
		padding-top: px(200) !important;
	}
	@include media-breakpoint-down(sm) {
		padding-top: px(170) !important;

		#filter-nav {
			top: px(46);
		}
	}
	@include media-breakpoint-down(xs) {
		padding-top: px(0) !important;

		#filter-nav {
			top: auto;
			position: relative;
			border-bottom: none;
		}
	}
}
// Products

.product-item {
	height: 100%;

	&.except-suggest {
		position: relative;
		border-left: 1px solid $gy-e;
		padding: 0 0 px(50) px(30);
		text-align: center;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background-color: $gy-e;
			height: px(50);
			width: px(4);
			display: block;
		}

		.img {
			max-width: 100%;
		}

		.content {
			padding: 0;
			height: inherit;
			position: static;
			border: none;

			&:before {
				display: none;
			}

			.btn-wp {
				text-align: center;
				position: absolute;
				margin: 0;
				bottom: 0;
				width: 100%;
				left: px(15);

				.btn-a {
					font-size: px(13);
				}
			}

			.title {
				font-size: px(20);
			}

			.title-sub {
				font-size: px(16);
			}

			.list.rating-wp {
				.item {
					padding-left: 0;
					padding-top: 0;

					.rate {
						width: px(70);
						position: relative;
						top: auto;
						left: auto;
						display: inline-block;
						vertical-align: middle;
					}

					.txt {
						font-size: px(12);
						padding-top: px(2);
						text-align: left;
						vertical-align: middle;
						display: inline-block;
					}
				}
			}
		}
		@include media-breakpoint-down(xs) {
			text-align: left;
			padding-left: 0;
			padding-bottom: 0;

			.img {
				max-width: px(220);
			}

			.content .btn-wp {
				margin-top: px(15);
				position: relative;
				bottom: auto;
				left: auto;
				text-align: left;
			}
		}
	}

	.img {
		max-width: px(220);
	}

	.content {
		margin-top: px(30);
		position: relative;
		border-left: 1px solid $gy-e;
		padding: px(10) 0 0 px(25);
		height: calc(100% - 250px);

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background-color: $gy-e;
			height: px(50);
			width: px(4);
			display: block;
		}

		.title {
			font-size: px(26);
			font-weight: 600;
			color: $bl-c;
			line-height: 1.25em;
			margin-bottom: px(20);
		}

		.title-sub {
			font-size: px(18);
			font-weight: 700;
			color: $gy-f;
		}

		.list.sources-wp {
			.item {
				position: relative;
				margin-top: px(8);

				.txt {
					font-size: px(13.5);
					line-height: 1.25em;
					font-weight: 400;
				}
			}
		}

		.list.rating-wp {
			.item {
				position: relative;
				padding-left: px(60);
				margin-top: px(8);

				.txt {
					font-size: px(13.5);
					line-height: 1.25em;
					font-weight: 700;
				}

				.rate {
					top: px(-3);
					position: absolute;
					left: 0;

					> span {
						width: px(9);
						height: px(9);
						display: inline-block;
						border-radius: 100%;
						background-color: #FF850A;
						margin-right: px(3);
					}
				}
			}
		}

		.btn-wp {
			margin-top: px(25);
		}
		@include media-breakpoint-down(md) {
			.list.rating-wp {
				.item {
					padding-top: px(20);
					padding-left: 0;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			margin-top: px(15);
			height: calc(100% - 235px);

			.title {
				font-size: px(22);
			}

			.title-sub {
				font-size: px(16);
				margin-bottom: 0;
			}
		}
	}
}
// Event

.event-item {
	padding: px(40) 0;
	border-bottom: 1px solid $gy-e;

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: $gy-e;
		width: px(50);
		height: px(4);
		display: block;
	}

	.date {
		font-style: italic;
		font-size: px(18);
		font-weight: 300;
	}

	.title {
		margin-top: px(10);
		font-size: px(20);
		font-weight: 700;
		line-height: 1.25em;
		color: $bl-c;
	}

	.txt {
		margin-top: px(10);
		font-size: px(16);
	}

	.btn-wp {
		margin-top: px(20);
	}
	@include media-breakpoint-down(sm) {
		padding: px(30) 0;

		.content {
			margin-top: px(20);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0;

		.content {
			margin-top: px(10);

			.date {
				font-weight: px(15);
			}

			.title {
				font-size: px(18);
			}

			.txt {
				font-size: px(15);
			}
		}
	}
}
// Article

.article-wp {
	.col-left {
		position: relative;
		border-right: 1px solid $gy-e;
		padding-right: px(50);

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			background-color: $gy-e;
			height: px(50);
			width: px(4);
			display: block;
		}
	}

	.date {
		font-size: px(18);
		font-style: italic;
		font-weight: 300;
		margin-bottom: px(10);
	}

	.title-article {
		@extend .h-like-c;
		margin-bottom: px(20);
	}

	.col-right {
		position: relative;
		padding-left: px(50);

		.btn-wp {
			width: 100%;
			text-align: center;
			margin-top: px(-35);
		}
	}

	.wysiwyg-wp {
		margin-top: px(30);
	}
	@include media-breakpoint-down(md) {
		.col-left {
			padding-right: px(15);
			border: none;

			&:before {
				display: none;
			}
		}

		.col-right {
			margin-top: px(50);
			padding-left: px(15);
		}
	}
	@include media-breakpoint-down(sm) {
		.col-right {
			margin-top: px(30);
		}
	}
}
// Article nav waypoints

#nav-article-wp {
	background-color: $gy-e;
	color: $wh-a;
	padding: px(15) 0 px(18);
	text-align: center;

	.item {
		display: inline-block;
		margin: 0 px(10);
	}

	.link {
		position: relative;
		display: block;
		font-weight: 600;
		font-size: px(13);

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: px(1);
			background-color: $wh-a;
			transition: all 0.2s ease-in-out;
			width: 0;
		}

		&.active {
			&:after {
				width: 100%;
			}
		}
	}

	#trigger-nav-article-wp {
		display: none;
		text-align: left;
		color: $wh-a;
		text-transform: uppercase;
		font-weight: 600;
		font-size: px(14);

		.txt {
			font-size: px(14);
			display: inline-block;
			margin-left: px(5);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(10) 0;

		.item {
			display: block;

			&:not(:last-child) {
				border-bottom: 1px solid rgba(255,255,255,0.5);
			}

			.link {
				padding: px(8) 0;
				font-weight: 400;

				&.active {
					font-weight: 700;
				}
			}

			.link.active:after {
				display: none;
			}
		}

		#trigger-nav-article-wp {
			position: relative;
			display: block;
		}

		.nav-article {
			display: none;
		}
	}
}
@include media-breakpoint-up(md) {
	#nav-article-wp .nav-article {
		display: block !important;
	}
}

.waypoints-section {
	position: relative;
	font-size: px(16);

	.waypoints-item {
		position: absolute;
		margin-top: px(-100);
	}
	@include media-breakpoint-down(sm) {
		.waypoints-item {
			margin-top: px(-80);
		}
	}
	@include media-breakpoint-down(xs) {
		.waypoints-item {
			margin-top: px(-40);
		}
	}
}
// Applications Article

.header-application {
	background-repeat: no-repeat;
	background-position: top center;

	.title {
		font-size: px(40);
		line-height: 1.2;
		font-size: px(38);
		font-weight: 800;
		padding: px(110) 0;
		color: $bk-a;
	}
	@include media-breakpoint-down(md) {
		text-align: center;

		.title {
			padding: px(210) 0 px(70);
		}
	}
	@include media-breakpoint-down(sm) {
		background-size: cover;
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.4);
			z-index: 2;
		}

		.title {
			font-size: px(26);
			padding: px(40) 0;
			background-position: center center;
			position: relative;
			z-index: 3;
			color: $wh-a;
			font-weight: 600;
		}
	}
	@include media-breakpoint-down(xs) {
		.title {
			font-size: px(21);
			padding: px(30) 0;
		}
	}
}

.header-product {
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
	color: $wh-a;

	.container {
		padding: px(50) px(15);
	}

	.img-fluid {
		max-height: px(600);
	}

	.title-wp {
		padding-top: px(150);
	}
	@include media-breakpoint-down(lg) {
		.img-fluid {
			max-height: px(500);
		}

		.title-wp {
			padding-top: px(125);
		}
	}
	@include media-breakpoint-down(md) {
		background-attachment: inherit;
		background-size: cover;
		background-position: center top;

		.container {
			padding: px(30) px(15);
		}

		.img-fluid {
			max-height: px(400);
		}

		.title-wp {
			padding-top: px(100);
		}
	}
	@include media-breakpoint-down(sm) {
		.container {
			padding: px(30) px(15);
		}

		.img-fluid {
			max-height: px(350);
		}

		.title-wp {
			padding-top: px(50);
		}
	}
	@include media-breakpoint-down(xs) {
		.container {
			padding: px(15) px(15) px(25);
		}

		.img-fluid {
			max-height: px(250);
			margin: px(10) 0 px(25);
		}

		.title-wp {
			padding-top: px(0);
		}
	}
}
// News Item

.news-item {
	position: relative;
	background-color: $wh-a;
	height: 100%;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;

	.img-wp {
		position: relative;

		.img {
			width: 100%;
		}

		.title {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: px(15);
			font-weight: 600;
			line-height: 1.4em;
			color: $wh-a;
			z-index: 2;
			background-color: rgba(0,0,0,0.75);
			padding: px(10) px(15);
		}
	}

	.content-wp {
		padding: px(15) px(20) px(20);
	}

	.date {
		font-style: italic;
		font-size: px(14);
		font-weight: 300;
	}

	.txt {
		margin-top: px(5);
		font-size: px(15);
		margin-bottom: 0;
		line-height: 1.4em;
	}

	.btn-wp {
		padding: px(0) px(20) px(20);
		text-align: center;
	}
	@include media-breakpoint-down(sm) {
		.txt {
			font-size: px(14);
			line-height: 1.3em;
		}

		.btn-a {
			font-size: px(13);
		}
	}
}
// Builder

.builder {
	background-size: cover !important;
	background-position: center center !important;
	// COLOR
	&.grey-light {
		&.txtcol,
		&.txtfull {
			background-color: $gy-a !important;
		}
	}
	&.grey-dark {
		&.txtcol,
		&.txtfull {
			background-color: #DBDCE0 !important;
		}
	}
	&.black {
		&.txtcol,
		&.txtfull {
			background-color: $bk-a !important;
		}
		.wysiwyg-wp {
			.title,
			p,
			ul li,
			ol li {
				color: $wh-a;
			}
			ul li:before {
				border-color: $wh-a;
			}
		}
	}

	&.products {
		background-color: $gy-a;
	}
	// PADDING
	&.documents,
	&.products,
	&.txtcol,
	&.txtfull,
	&.video {
		padding: px(60) 0;
		@include media-breakpoint-down(md) {
			padding: px(50) 0;
		}
		@include media-breakpoint-down(sm) {
			padding: px(40) 0;
		}
		@include media-breakpoint-down(xs) {
			padding: px(30) 0;
		}
	}
	&.accessories {
		padding: px(60) 0 px(30);
		@include media-breakpoint-down(md) {
			padding: px(50) 0 px(30);
		}
		@include media-breakpoint-down(sm) {
			padding: px(40) 0 px(20);
		}
		@include media-breakpoint-down(xs) {
			padding: px(30) 0 px(10);
		}
	}

	// SPECIFIC
	&.txtfull {
		text-align: center;
		@include media-breakpoint-down(sm) {
			text-align: left;
		}
	}

	&.accessories-list {
		background-color: $gy-a;
		padding: px(30) 0 px(50);

		.item {
			position: relative;
			padding: px(30) 0;
			border-bottom: 1px solid $gy-e;

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: $gy-e;
				width: px(50);
				height: px(4);
				display: block;
			}

			.title {
				@extend .h-like-e;
			}

			.txt {
				margin-top: px(10);
				margin-bottom: 0;
			}

			.col-wp {
				display: table;
				width: 100%;
				table-layout: fixed;
				table-layout: fixed;

				.col-left,
				.col-right {
					display: table-cell;
					vertical-align: top;
				}

				.col-left {
					width: px(160);

					.img-wp {
						max-width: 100%;
						border-radius: 100%;
					}
				}

				.col-right {
					padding-left: px(30);
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding: px(15) 0 px(30);
		}
		@include media-breakpoint-down(xs) {
			padding: px(10) 0 px(20);

			.item {
				padding: px(25) 0;

				.col-wp {
					display: block;

					.col-left,
					.col-right {
						display: block;
						width: 100%;
						padding-left: 0;

						&.col-right {
							margin-top: px(20);
						}
					}
				}
			}
		}
	}

	&.documents {
		background-color: $gy-e;
		color: $wh-a;
	}

	&.documents-list {
		background-color: $gy-a;
		padding: px(30) 0 px(50);

		.item {
			position: relative;
			padding: px(30) 0;
			border-bottom: 1px solid $gy-e;

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: $gy-e;
				width: px(50);
				height: px(4);
				display: block;
			}

			.col-wp {
				display: table;
				width: 100%;
				table-layout: fixed;
			}

			.col-left,
			.col-middle,
			.col-right {
				display: table-cell;
				vertical-align: middle;

				&.col-left {
					width: px(75);
				}

				&.col-right {
					width: px(225);
					text-align: right;
				}

				&.col-middle {
					width: 100%;
				}

				.title {
					font-size: px(16);
					font-weight: 600;
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding: px(15) 0 px(30);

			.btn-a {
				font-size: px(12);
				min-width: px(120);
			}

			.item {
				.col-right {
					width: px(150) !important;
				}

				.col-middle {
				}
			}
		}
		@include media-breakpoint-down(xs) {
			padding: px(10) 0 px(20);

			.item {
				padding: px(25) 0;

				.col-wp {
					display: block;
				}

				.col-left {
					display: none !important;
				}

				.col-right {
					display: block !important;
					text-align: left !important;
					margin-top: px(10);
				}

				.col-middle {
					display: block !important;
					width: 100% !important;

					.title {
						font-size: px(15) !important;
					}
				}
			}
		}
	}

	&.before-after {
		background-position: center top;
		background-repeat: no-repeat;
		background-attachment: fixed;
		position: relative;

		.content {
			padding: px(300) px(15);
		}
		@include media-breakpoint-down(lg) {
			.content {
				padding: px(200) px(15);
			}
		}
		@include media-breakpoint-down(md) {
			.content {
				padding: px(150) px(15);
			}
		}
		@include media-breakpoint-down(sm) {
			.content {
				padding: px(100) px(15);
			}
		}
		@include media-breakpoint-down(xs) {
			background-size: cover;
			background-attachment: inherit;
			position: relative;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.5);
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}

			.content {
				position: relative;
				z-index: 2;
				color: $wh-a;
				padding: px(50) px(15);
			}
		}
	}

	&.video {
		background-color: $gy-b;

		.icon-play {
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				right: px(0);
				opacity: 0.5;
				margin-top: px(-175);
				background-image: url("../images/illustrations/builder-video-icon.png");
				width: px(350);
				height: px(350);
				z-index: 1;
			}
			@include media-breakpoint-down(md) {
				&:after {
					display: none;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.img-fluid {
				max-width: px(300);
			}
		}

		.content {
			position: relative;
			z-index: 2;
		}
	}

	&.products {
		.products-wp > *:first-child .product-item {
			border-left: none;

			&:before {
				display: none;
			}
		}
		@include media-breakpoint-down(md) {
			.products-wp > *:nth-child(3) .product-item {
				border-left: none;

				&:before {
					display: none;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			.products-wp .product-item {
				border-left: none !important;

				&:before {
					display: none !important;
				}
			}
		}
	}
}
// Product intro

.product-intro {
	@include media-breakpoint-down(xs) {
		text-align: center;
	}
	.col-border {
		border-right: 1px solid $gy-e;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: px(-5);
			background-color: $gy-e;
			height: px(50);
			width: px(4);
			display: block;
		}
		@include media-breakpoint-down(sm) {
			border: none;
			&:before {
				display: none;
			}
		}
	}

	.list {
		list-style: none;

		.item {
			position: relative;
			display: table;
			table-layout: fixed;

			&:not(:last-child) {
				margin-bottom: px(25);
				@include media-breakpoint-down(sm) {
					margin-bottom: px(25);
				}
			}

			.nb-wp,
			.txt {
				display: table-cell;
				vertical-align: middle;
			}

			@include media-breakpoint-down(sm) {
				.nb-wp,
				.txt {
					vertical-align: top;
				}
				.txt {
					padding-top: px(25);
				}
			}

			@include media-breakpoint-down(xs) {
				display: block;
				text-align: center;
				.nb-wp,
				.txt {
					text-align: center;
					display: block;
					width: 100% !important;
					padding-left: 0 !important;
				}
				.txt {
					padding-top: px(10);
				}
			}


			.nb-wp {
				width: px(90);
			}

			.nb {
				display: inline-block;
				width: px(90);
				padding-top: px(10);
				height: px(90);
				border-width: 5px;
				border-style: solid;
				text-align: center;
				font-weight: 600;
				font-size: px(56);
				line-height: 1em;
				border-radius: 100%;
			}

			.txt {
				width: calc(100% - 100px);
				padding-left: px(15);

			}
		}
	}
}

// Ios bg Attachment fix
.is-os {
	.about-ambassador,
	.about-history,
	.builder.before-after {
		background-attachment: inherit;
		background-size: cover;
	}
}

// WPAS LOADER
#wpas-load {
	padding-top: px(30);
	display: block;
	text-align: center;
}
#wpas-load-btn {
	@extend .btn-a;
	@extend .white;
}
.wpas-reset.hack-hide{
	position: absolute;
	opacity: 0;
	left: -10000%;
	top: -10000%;
}

// OVERRIED STYLE WP
.screen-reader-text,
.nav-next-text,
.nav-prev-text {
	@extend .sr-only;
}
.navigation.pagination {
	padding-top: px(50);
	text-align: center;
	.page-numbers:not(.next):not(.prev) {
		margin: 0 px(4);
		background-color: $wh-a;
		font-size: px(15);
		padding: px(2) px(8);
		color: $bl-c;
		text-align: center;
		line-height: 1em;
		&.current {
			background-color: $bl-c;
			color: $wh-a;
		}
	}
	@include media-breakpoint-down(sm){
		padding-top: px(30);
	}
}
