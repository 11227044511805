.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	text-align: center;
	height: 2px;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 3px;
		height: 2px;
	}

	button {
		width: 30px;
		height: 2px;
		background-color: rgba(255,255,255,0.5);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: rgba(255,255,255,1);
	}

	button:focus,
	button:hover {
		background-color: rgba(255,255,255,1);
	}
}

#slick-news-wp {
	position: relative;

	.item {
		position: relative;
		background-size: cover;
		background-position: center center;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			position: absolute;
			top: 0;
			z-index: 1;
			left: 0;
		}
	}

	.container {
		position: relative;
		z-index: 2;
		height: px(300);
		padding: 0 px(100);
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
	}

	.title {
		font-size: px(36);
		font-weight: 600;
		line-height: 1.3em;
		color: $wh-a;
	}
	.txt {
		font-size: px(20);
		margin-top: px(8);
		font-weight: 400;
		line-height: 1.2em;
		color: $wh-a;
	}

	.btn-wp {
		margin-top: px(30);
	}

	.slick-dots {
		position: absolute;
		width: 100%;
		bottom: px(20);
		margin: 0;
	}

	.arrow-next,
	.arrow-prev {
		position: absolute;
		top: 0;
		height: 100%;
		width: px(100);
		font-size: px(24);
		color: $wh-a;
		text-shadow: 0 1px 2px rgba(0,0,0,0.2);
		background-color: transparent;
		opacity: 0.6;
		transition: all 0.2s ease-in-out;

		&:focus,
		&:hover {
			opacity: 1;
		}
	}

	.arrow-prev {
		left: px(0);
	}

	.arrow-next {
		right: px(0);
	}

	.slick-slide .title,
	.slick-slide .txt {
		opacity: 0;
		transform: translateY(-10px) scale(.95);
		transition: all 0.25s ease-in-out 0.25s;
	}

	.slick-slide .btn-wp {
		opacity: 0;
		transform: translateY(10px) scale(1);
		transition: all 0.4s ease-in-out 0.5s;
	}

	.slick-slide.animation .title,
	.slick-slide.animation .txt {
		opacity: 1;
		transform: translateY(0) scale(1);
	}

	.slick-slide.animation .btn-wp {
		opacity: 1;
		transform: translateY(0) scale(1);
	}

	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(32);
		}
	}
	@include media-breakpoint-down(md) {
		.title {
			font-size: px(26);
		}
		.txt {
			font-size: px(18);
		}
		.container {
			padding: 0 px(50);
		}
		.arrow-next,
		.arrow-prev {
			width: px(50);
		}
	}
	@include media-breakpoint-down(xs) {
		margin-top: px(15);
		.item {
			background-image: none !important;
			&:after {
				display: none;
			}
		}
		.content {
			background-color: shade($gy-a, 10%);
			padding: px(5) px(20) px(20) px(20);
		}
		.container {
			height: auto;
			padding: 0 px(15);
		}
		.arrow-next,
		.arrow-prev {
			display: none !important;
		}
		.title {
			margin-top: px(10);
			color: $bl-c;
			font-size: px(18);
		}
		.txt {
			color: $bl-c;
			font-size: px(16);
		}
		.btn-wp {
			margin-top: px(10);
		}
		.btn-a {
			background: rgb(116,112,156);
			background: -moz-linear-gradient(top,  rgba(116,112,156,1) 1%, rgba(35,26,97,1) 100%);
			background: -webkit-linear-gradient(top,  rgba(116,112,156,1) 1%,rgba(35,26,97,1) 100%);
			background: linear-gradient(to bottom,  rgba(116,112,156,1) 1%,rgba(35,26,97,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#74709c', endColorstr='#231a61',GradientType=0 );
			color: $wh-a !important;
			&:hover,
			&:focus {
				background: rgb(116,112,156);
				background: -moz-linear-gradient(top,  shade(rgba(116,112,156,1),10%) 1%, shade(rgba(35,26,97,1),10%) 100%);
				background: -webkit-linear-gradient(top,  shade(rgba(116,112,156,1),10%) 1%,shade(rgba(35,26,97,1),10%) 100%);
				background: linear-gradient(to bottom,  shade(rgba(116,112,156,1),10%) 1%,shade(rgba(35,26,97,1),10%) 100%);
			}
		}
		.slick-dots {
			position: relative;
			bottom: auto;
			left: auto;
			margin-top: px(25);
			button {
				background-color: $bl-a;
			}
			.slick-active button {
				background-color: $bl-c;
			}

			button:focus,
			button:hover {
				background-color: $bl-c;
			}

		}
		.slick-slide .btn-wp {
			opacity: 0;
			transform: translateY(0) scale(1);
			transition: all 0.4s ease-in-out 0.5s;
		}
	}

}
