// General :: Remove autofill (Chrome)

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border-color: $bl-a;
	background: transparent !important;
	background-color: transparent !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px transparent inset !important;
	transition: background-color 5000s ease-in-out 0s;
}
// Input :: Text

.form-control {
	color: $gy-e !important;
	border: none;
	border-bottom: 1px solid $gy-c;
	box-shadow: none;
	border-radius: 0;
	padding: px(10) px(10) px(10) 0;
	background-color: transparent !important;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:focus,
	&.filled {
		border-color: $bl-c;
		background-color: transparent !important;
		color: $bl-c !important;
		box-shadow: none;
	}
}
// Input :: Text Placeholder

.form-control::placeholder {
	color: $gy-d;
	opacity: 1;
}
// Input :: Checkbox & Radio

.checkboxradio-wp {

	.wpcf7-list-item {
		position: relative;
		display: block;

		> .wpcf7-list-item-label,
		> input {
			cursor: pointer;
		}

		a {
			text-decoration: underline;
			color: $bl-c;
		}

		> input {
			position: absolute;
			-moz-opacity: 0;
			opacity: 0;
			filter: alpha(opacity = 0);
			width: 100%;
			height: 100%;
		}

		> input[type=checkbox] + .wpcf7-list-item-label,
		> input[type=radio] + .wpcf7-list-item-label {
			display: block;
			padding: px(6) 0 0 px(38);
			min-height: px(30);
			font-size: px(13);
			color: $gy-d;
			font-weight: 400;
			background-image: url("../images/icons/sprite-checkbox_radio.png");
			line-height: 1.3em;
			background-repeat: no-repeat;
		}

		> input[type=radio] + .wpcf7-list-item-label {
			background-position: 0 0;
		}


		> input[type=radio]:checked + .wpcf7-list-item-label {
			background-position: 0 0;
		}

		> input[type=checkbox]:checked + .wpcf7-list-item-label {
			background-position: 0 -292px;
			color: $bl-c;
		}
		> input[type=checkbox] + .wpcf7-list-item-label {
			background-position: 0 -446px;
		}
	}
}
// Input :: Radio ON/OFF

.btn-switch-wp {
	position: relative;
	display: flex;
	align-items: center;

	.label {
		padding-left: 15px;
		color: $bk-a;
		font-weight: 400;
		cursor: pointer;
	}

	.checkbox-toggle {
		position: absolute;
		margin-left: -9999px;
		visibility: hidden;
	}

	.checkbox-toggle + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.checkbox-toggle-round + label {
		margin: 0;
		padding: 2px;
		width: 55px;
		height: 31px;
		background-color: $bk-a;
		border-radius: 60px;
		transition: background 0.2s;
	}

	.checkbox-toggle-round + label:after,
	.checkbox-toggle-round + label:before {
		display: block;
		position: absolute;
		content: "";
	}

	.checkbox-toggle-round + label:after {
		top: 4px;
		left: 4px;
		bottom: null;
		width: 23px;
		height: 23px;
		background-color: $wh-a;
		border-radius: 52px;
		transition: margin 0.2s, background 0.2s;
	}

	.checkbox-toggle-round:checked + label {
		background-color: tint($bk-a,50%);
	}

	.checkbox-toggle-round:checked + label:after {
		margin-left: 23px;
	}
}

// Input :: Select Structure

.selectpicker-wp {
	position: relative;

	&.except-filter {
		.dropdown-toggle {
			background-color: $wh-a !important;
			border-radius: 30px;
			padding-left: px(20);
			padding-right: px(20);
			border: 1px solid $gy-c !important;
			box-shadow: 1px 1px 1px inset rgba(0,0,0,0.1);
			&:after {
				right: px(9);
				color: $gy-e;
				margin-top: px(-1);
			}
		}
	}

	.bootstrap-select {
		width: 100% !important;
		&.changed .dropdown-toggle {
			color: $bl-c !important;
			border-bottom-color: $bl-c;
		}
	}

	.filter-option {
		@include ellipsis;
		line-height: 1em;
	}

	.dropdown-toggle {
		display: block;
		width: 100%;
		padding-top: px(9);
		color: $gy-d;
		padding-right: px(30) !important;
		border-bottom: 1px solid $gy-c;
		outline: none;
		height: px(33);
		line-height: 1em;
	}

	.dropdown-toggle:focus {
		outline: none !important;
	}

	.dropdown-toggle:after {
		@include position(absolute, 50% 0px null null);
		margin-top: -3px;
		color: $gy-d;
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(-180deg);
	}
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
	overflow: visible;
}
// Input :: Select Dropdown

.bootstrap-select.btn-group .dropdown-menu.inner {
	display: block;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: $bl-c;
	color: $wh-a;
}

.bootstrap-select.btn-group .dropdown-menu {
	padding: 0;

	li a,
	li a span.text {
		display: block;
	}

	li a {
		display: block;
		padding: px(5) px(15);
	}

	li a {
		outline: none !important;
	}

	li:not(:last-child) {
		border-bottom: 1px solid #f8f9fa;
	}

	li a:focus,
	li a:hover {
		background-color: #f8f9fa;
	}
}

.bootstrap-select .dropdown-menu .inner {
	max-height: 200px !important;
}

.bootstrap-select .dropdown-menu {
	min-width: 100% !important;
}

// Validation :: Error

.wpcf7-not-valid-tip {
	display: block;
	color: #a94442;
	font-size: px(11);
	margin: px(5) 0;
}
.wpcf7-response-output {
	text-align: center;
	margin-top: px(20);
	@extend .alert;
	&.wpcf7-validation-errors {
		@extend .alert-danger;
		display: block !important;
	}
	&.wpcf7-mail-sent-ok {
		@extend .alert-success;
		display: block !important;
	}
	&.wpcf7-display-none {
		display: none;
	}
}

// CONTACT FORM 7
.screen-reader-response {
	@extend .sr-only;
}
